<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap mt--150">
      <v-container>
            <h3 class="mb--100">ΟΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ του προγράμματος «ελίν up επιβράβευση »</h3>
            
            <p>Η παρούσα εφαρμογή (εφεξής η «Εφαρμογή») η οποία διέπεται από τους Όρους και Προϋποθέσεις Χρήσης που περιγράφονται στην ιστοσελίδα της και κατωτέρω,
             αποτελεί διαδικτυακή εφαρμογή της εταιρίας “RoadCube" (εφεξής η “RoadCube"), μέσω της οποίας ο Χρήστης έχει τη δυνατότητα πληρωμής ή προπληρωμής με κάρτα
             (χρεωστική, πιστωτική ή προπληρωμένη), μέσω του κινητού του τηλεφώνου, μεταξύ άλλων καυσίμων, προϊόντων (Mini Market) και υπηρεσιών που διατίθενται από
             τις συμμετέχουσες εταιρείες στην Εφαρμογή όπως ενδεικτικά από τα πρατήρια του δικτύου της ΕΛΙΝΟΙΛ με το σήμα ‘ΕΛΙΝ’. </p><p>Ταυτόχρονα, έχει τη δυνατότητα να συλλέγει,
             με την αγορά των προϊόντων ή/και υπηρεσιών αυτών, πόντους στην Εφαρμογή όπως ενδεικτικά από την ελίν, που αντιστοιχούν σε συγκεκριμένα δώρα
             (πρόγραμμα επιβράβευσης πιστού καταναλωτή ‘loyalty’). </p><p>Τα δώρα που προσφέρονται στην Εφαρμογή είναι σύμφωνα με τους όρους της κάθε συμμετέχουσας
             στην Εφαρμογή εταιρίας .</p><p> Οι χρήστες που λαμβάνουν υπηρεσίες από την εταιρία ΕΛΙΝΟΙΛ θα λαμβάνουν πόντους σύμφωνα με το πρόγραμμα επιβράβευσης πιστού 
             καταναλωτή (loyalty)της ΕΛΙΝΟΙΛ «ελιν up επιβράβευση» (εφεξής το «Πρόγραμμα). </p><p>Στο Πρόγραμμα αυτό ο Χρήστης συμμετέχει οικειοθελώς και αυτοβούλως και
             μπορεί να διαγραφεί κατόπιν σχετικού αιτήματός του . </p><p><b>ΟΡΙΣΜΟΙ </b></p><p>Ο όρος "Όροι και Προϋποθέσεις χρήσης του προγράμματος «ελίν up επιβράβευση» " αναφέρεται 
             στους όρους της παρούσας και τελευταία έκδοσή τους. Ο όρος "Γενικοί Όροι και Προϋποθέσεις Roadcube" αναφέρεται στους όρους της Εφαρμογής και στην
             τελευταία έκδοσή τους. Ο όρος "Εταιρία" αναφέρεται στην εταιρία με την επωνυμία «ΕΛΙΝΟΙΛ ΕΛΛΗΝΙΚΗ ΕΤΑΙΡΙΑ ΠΕΤΡΕΛΑΙΩΝ Α.Ε.» που έχει αναρτήσει στα 
             πρατήριά της το σήμα «ΕΛΙΝ».</p><p> Ο όρος “Εφαρμογή” ή “Πλατφόρμα” : αναφέρεται στην διαδικτυακή εφαρμογή της RoadCube μέσω της οποίας ο Χρήστης έχει τη 
             δυνατότητα πληρωμής ή προπληρωμής με κάρτα (χρεωστική, πιστωτική ή προπληρωμένη) - μέσω του κινητού του τηλεφώνου - καυσίμων, προϊόντων (Mini Market)
             και υπηρεσιών που διατίθενται από τις συμμετέχουσες εταιρίες στην Εφαρμογή όπως ενδεικτικά από τα πρατήρια του δικτύου της ΕΛΙΝΟΙΛ με το σήμα ‘ΕΛΙΝ’,
             καθώς και τη δυνατότητα να συλλέγει, με την αγορά των προϊόντων ή/και υπηρεσιών αυτών, πόντους στην Εφαρμογή όπως ενδεικτικά από την ελίν, που αντιστοιχούν 
             σε συγκεκριμένα δώρα (πρόγραμμα επιβράβευσης πιστού καταναλωτή ‘loyalty’). Η εφαρμογή έχει σχεδιαστεί και λειτουργεί σύμφωνα με την ισχύουσα ελληνική και 
             κοινοτική νομοθεσία.</p><p> Ο όρος <b>«RoadCube»</b> αναφέρεται στην εταιρία με την επωνυμία «ROADCUBE INC ΥΠΟΚΑΤΑΣΤΗΜΑ ΑΛΛΟΔΑΠΗΣ». Ο όρος "Πελάτης” ή “Χρήστης 
             (ONLINE ή OFFLINE) ” ή “Καταναλωτής" αναφέρεται : α) στον καταχωρημένο Χρήστη (ΟΝLINE) που χρησιμοποιεί την Εφαρμογή με σκοπό την πληρωμή ή προπληρωμή 
             καυσίμων ή/και την αγορά αγαθών και υπηρεσιών που προσφέρονται μέσω της Εφαρμογής όπως από την «ΕΛΙΝ» μέσω των πρατηρίων της και β) στον OFFLINE πελάτη 
             ο οποίος πληρώνει με μετρητά ή με κάρτα (χρεωστική, πιστωτική ή προπληρωμένη) χωρίς τη χρήση του κινητού του τηλεφώνου, η με κάρτα loyalty έντυπη η εικονική , 
             καύσιμα/αγαθά ή υπηρεσίες που προσφέρονται μέσω της Εφαρμογής, όπως από την ΕΛΙΝΟΙΛ, όντας στον χώρο των πρατηρίων της, ταυτόχρονα δε επωφελείται του προγράμματος 
             loyalty «ελίν up επιβράβευση» που περιγράφεται παρακάτω. </p><p>Ο όρος <b>«Πρατήρια ΕΛΙΝ»</b> αναφέρεται στα πρατήρια της ΕΛΙΝΟΙΛ που έχουν ενταχθεί στο Πρόγραμμα και 
             εμφανίζονται στην Εφαρμογή. Ο όρος «Πρατήρια Roadcube» αναφέρεται σε όλα τα πρατήρια που έχουν ενταχθεί και εμφανίζονται στην Εφαρμογή που δεν συμμετέχουν 
             στο πρόγραμμα ελίν up επιβράβευση. </p><p>Ο όρος <b>«Πρόγραμμα»</b> αναφέρεται στο πρόγραμμα επιβράβευσης πιστού πελάτη » «ελιν up επιβράβευση» , που έχει δημιουργηθεί από 
             την ΕΛΙΝΟΙΛ. Ο όρος «Προμηθευτής» αναφέρεται σε κάθε εταιρία που έχει συνάψει σύμβαση με την ΕΛΙΝΟΙΛ προκειμένου να την προμηθεύει με τα αντικείμενα που 
             περιλαμβάνονται στον «κατάλογο ελίν up επιβράβευση » για την εκτέλεση του Προγράμματος . </p><p>Ο όρος <b>«Συνεργάτης»</b> αναφέρεται σε κάθε εταιρία που έχει συνάψει 
             σύμβαση με την ΕΛΙΝΟΙΛ ή/και την Roadcube προκειμένου ο Συνεργάτης να επιβραβεύει τους πελάτες του προσφέροντας πόντους «ελίν up επιβράβευση» ή εκπτώσεις 
             βάσει των ειδικότερων όρων που έχουν συμφωνηθεί μεταξύ της ΕΛΙΝΟΙΛ και του Συνεργάτη ή της Roadcube και του Συνεργάτη. Οι παρόντες Όροι και Προϋποθέσεις 
             Χρήσης μπορεί να τροποποιούνται/αναθεωρούνται από την "Εταιρία" οποτεδήποτε, με ταυτόχρονη ενημέρωση του Χρήστη μέσω της Εφαρμογής.</p><p> Η συνέχιση της χρήσης 
             της Εφαρμογής από τον Χρήστη νοείται ως αποδοχή και των τροποποιημένων Όρων και Προϋποθέσεων Χρήσης. ΓΕΝΙΚΑ Ο Χρήστης οφείλει, αφού «κατεβάσει» δωρεάν την 
             Εφαρμογή στο κινητό του τηλέφωνο, να διαβάσει προσεκτικά τους Γενικούς Όρους και Προϋποθέσεις Roadcube της Εφαρμογής και να τους αποδεχθεί κλικάρωντας επάνω τους.</p>
             <p> Οι παρόντες όροι αποτελούν μέρος των Γενικών όρων και προϋποθέσεων Roadcube της Εφαρμογής και αφορούν κυρίως στην ΕΛΙΝΟΙΛ. Σε περίπτωση σύγκρουσης των δύο
              υπερτερούν οι Γενικοί όροι και προϋποθέσεις Roadcube της Εφαρμογής όπως είναι διαθέσιμοι στη σελίδα εγγραφής. Με τη χρήση της Εφαρμογής ο Χρήστης επιβεβαιώνει 
              ότι έχει διαβάσει, κατανοήσει και αποδέχεται ανεπιφύλακτα να δεσμεύεται από αυτούς τους Όρους και Προϋποθέσεις και τους ισχύοντες σχετικούς νόμους και κανονισμούς. 
              Αν ο Χρήστης δεν συμφωνεί με όλα τα ανωτέρω ή με κάποιο/α από αυτά δεν πρέπει να συνδιαλλαγεί με την Εταιρία. Άλλως θα θεωρηθεί ότι έχει σιωπηρώς 
              ανεπιφύλακτα αποδεχθεί τους παρόντες όρους και όπως αυτοί ισχύουν αναλογικώς σε κάθε περίπτωση. Σε περίπτωση απόρριψης για οιονδήποτε λόγο της πληρωμής μέσω 
              κάρτας από την τράπεζα (π.χ. υπέρβαση πιστωτικού ορίου, μη επαρκές υπόλοιπο σε συνδεδεμένο λογαριασμό, υπόνοια περί μη εγκεκριμένης ή δόλιας χρήσης της κάρτας), 
              δεν πραγματοποιείται η αγορά του προϊόντος ή/και η παροχή υπηρεσιών προς τον Χρήστη και η RoadCube και η ΕΛΙΝΟΙΛ ουδεμία ευθύνη φέρουν από αυτήν την αιτία. 
              Νόμιμη ηλικία Ο Χρήστης για να κάνει χρήση της Εφαρμογής πρέπει να είναι τουλάχιστον δεκαοκτώ (18) ετών. </p><p><b>«Κατεβάζοντας» την Εφαρμογή </b>, τεκμαίρεται ότι ο Χρήστης 
              δηλώνει ότι έχει συμπληρώσει το 18ο έτος και ότι έχει διαβάσει, κατανοήσει και αποδέχεται ανεπιφύλακτα τους όρους της παρούσας Σύμβασης. Υπηρεσίες - Πώληση 
              προϊόντων ή/και υπηρεσιών Η Εταιρία αναρτά στην Εφαρμογή τα πρατήρια με το σήμα ελίν που συμμετέχουν στο πρόγραμμα. Για τις πληροφορίες σχετικά με τα καύσιμα, 
              τα προϊόντα και τις παρεχόμενες υπηρεσίες κάθε πρατηρίου αποκλειστικά υπεύθυνος έναντι του Χρήστη είναι ο πρατηριούχος, ο οποίος ευθύνεται για το είδος αυτών, 
              τις τιμές τους, αλλά και την επικαιροποίηση αυτών. </p><p>Επιπλέον, η Εταιρία αναρτά στην Εφαρμογή τους πόντους που κερδίζει ο κάθε χρήστης για τις αγορές του στο πλαίσιο 
              του προγράμματος επιβράβευσης του πιστού χρήστη «ελίν up επιβράβευση» , τα δώρα και τη διαδικασία απόκτησης αυτών. Πολιτική Επιστροφών ΕΛΙΝΟΙΛ Με την υποβολή της 
              παραγγελίας τεκμαίρεται αμάχητα ότι ο Χρήστης έλαβε γνώση των Όρων και Προϋποθέσεων του προγράμματος «ελίν up επιβράβευση» και εφεξής, υποκείμενος στους παρόντες 
              όρους και προϋποθέσεις, δεν δικαιούται να υπαναχωρήσει από την αγορά ή να την καταγγείλει ή να ζητήσει ακύρωση της ισόποσης χρέωσης της κάρτας του από την τράπεζα. 
              Μπορεί όμως ο Χρήστης αντί της υπαναχώρησης, καταγγελίας ή ακύρωσης να ακολουθήσει την κατωτέρω περιγραφόμενη διαδικασία εξαργύρωσης της πληρωμής ή προπληρωμής που 
              έκανε, για την τήρηση της οποίας αποκλειστικά υπεύθυνη είναι η ΕΛΙΝΟΙΛ.</p><p> Ως προς τα προϊόντα που διατίθενται από mini market του πρατηρίου ο Χρήστης διατηρεί επιπλέον 
              τα εκ του Νόμου δικαιώματά του ως καταναλωτή. Το δώρο που έχει επιλέξει ο Χρήστης ως επιβράβευση από τη συλλογή πόντων «ελίν up επιβράβευση» από το πρατήριο ελίν , 
              δεν επιστρέφεται, δεν αντικαθίσταται με χρήματα, ούτε με άλλη υπηρεσία ή προϊόν ακόμα και αν ο Χρήστης προτίθεται να το προμηθευτεί καταβάλλοντας επιπλέον χρηματική 
              διαφορά Περιορισμός ευθύνης/ αποζημίωση Η Εταιρία και η Roadcube διατηρούν το δικαίωμα καθορισμού των όρων και προϋποθέσεων έκτασης της ευθύνης τους, του περιορισμού 
              αυτής, καθώς και της οφειλόμενης αποζημίωσης. Όριο στο δικαίωμα αυτό θέτει ο νόμος.</p><p> Η Εταιρία είναι υπεύθυνη για τις υπηρεσίες που προσφέρει στους Χρήστες μέσω της 
              Εφαρμογής και του «ελίν up επιβράβευση» και η Εφαρμογή για την καλή λειτουργία της πλατφόρμας της Εφαρμογής. Η Roadcube δεν αναλαμβάνει καμία ευθύνη απέναντι στους 
              Χρήστες σε σχέση με την ποιότητα των υπηρεσιών, παροχών, καυσίμων, προϊόντων που προσφέρουν τρίτες εταιρείες και των δώρων και των εγγυήσεων και της παράδοσής και 
              της επιστροφής τους μέσω της Εφαρμογής της.</p><p> Η Roadcube παρέχει την Εφαρμογή ως μέσον και μόνο άμεσης συναλλαγής των τρίτων εταιρειών που συμμετέχουν στην Εφαρμογή 
              της ως Πάροχοι (εφεξής “Πάροχοι”) και των Χρηστών της. Χρήση των Πληροφοριών- Προσωπικά δεδομένα : </p><p>( Α )Από τον Χρήστη ONLINE, κατά την συμπλήρωση της φόρμας 
              εγγραφής 
              το κινητό θα ζητηθεί:</p><p> 1. Να αποδεχθεί τους όρους χρήσης της Εφαρμογής </p><p>2. Να καταχωρήσει τον αριθμό του κινητού του τηλεφώνου (Iσχύει για κινητά που ξεκινούν 
              από 69…).</p><p> 3. Να καταχωρήσει κωδικό πρόσβασης Στη συνέχεια θα γίνει επαλήθευση ταυτότητας Χρήστη με SMS. Ο Χρήστης θα λάβει με SMS έναν μοναδικό κωδικό τον 
              οποίο θα καταχωρεί στην Εφαρμογή, προκειμένου να ολοκληρωθεί η εγγραφή.Προκειμένου να προβεί σε συναλλαγή ο χρήστης θα πρέπει : </p><p>4. Να καταχωρήσει τον τύπο 
              του οχήματος που διαθέτει (έως 3 οχήματα) /πινακίδα /μάρκα / μοντέλο. </p><p>5. Να καταχωρήσει τον αριθμό της κάρτας του (χρεωστικής, πιστωτικής ή προπληρωμένης) 
              </p><p>6. Να αποδεχθεί τους όρους χρήσης ελιν up επιβράβευση Να χορηγεί η μη συναίνεσή …………….. Όταν γίνεται μεταβολή στη SΙM card ο χρήστης πρέπει να ξανακάνει 
              εγγραφή στην εφαρμογή RoadCube.</p><p> Για τη λειτουργία της Εφαρμογής, η RoadCube συλλέγει τα στοιχεία που ο Χρήστης της δίνει κατά την συμπλήρωση της φόρμας 
              εγγραφής του ως πελάτης. </p><p>( Β ) Από τον Χρήστη OFFLINE, κατά τη συμπλήρωση της φόρμας εγγραφής ζητείται να καταχωρήσει:</p><p> 1. Φύλο (άνδρας /γυναίκα) </p><p>2. Επώνυμο</p><p> 3. 
              Ονομα </p><p>4. Ημερομηνία γέννησης</p><p> 5. Δ/νση κατοικίας (περιοχή/οδός /αριθμός/ΤΚ)</p><p> 6. Τον αριθμό του κινητού τηλεφώνου του (μέσω αυτού θα γίνεται η ταυτοποίηση ) </p><p>7. 
              Τον αριθμό σταθερού τηλεφώνου </p><p>8. Το e-mail του ΧΡΕΙΑΖΕΤΑΙ ?? </p><p>Ο Χρήστης δίνει τη ρητή συγκατάθεσή του ώστε η Roadcube μέσω της Εφαρμογής να συγκεντρώνει 
              για σκοπούς λειτουργίας της Εφαρμογής και της εξυπηρέτησης του Χρήστη προσωπικά του στοιχεία δηλαδή ενδεικτικά: όνομα, επώνυμο, ταχ. διεύθυνση, ηλεκτρ. 
              διεύθυνση(e-mail), αριθμό τηλεφώνου. </p><p>Επίσης, ο Χρήστης δίνει την ρητή συγκατάθεσή του ώστε η Roadcube να επεξεργάζεται τα ανωτέρω προσωπικά του στοιχεία 
              με τους Προμηθευτές/Συνεργάτες της ΕΛΙΝΟΙΛ και την Εταιρία στο μέτρο που αυτό είναι απαραίτητο για την λειτουργία της Εφαρμογής αλλά και την εκτέλεση του 
              προγράμματος πιστότητας. </p><p>Ο χρήστης από την στιγμή που πραγματοποιεί συναλλαγή σε πρατήριο ελίν γίνεται πελάτης της ΕΛΙΝΟΙΛ και δίνει την ρητή συγκατάθεσή 
              του τόσο στην Εταιρία όσο και στην Roadcube προκειμένου να λαμβάνει ενημερωτικό υλικό για το Πρόγραμμα αλλά και για τα προϊόντα τόσο της Εταιρίας όσο και 
              της Roadcube. </p><p>ΣΥΝΑΙΝΩ ΝΑ ΛΑΜΒΑΝΩ ΕΝΗΜΕΡΩΤΙΚΟ ΥΛΙΚΟ ΑΠΟ ΤΗΝ ΕΛΙΝΟΙΛ ΚΑΙ ΤΗΝ ROADCUBE ΚΟΥΤΙ ΜΕΣΩ : ……………………….. ……………………… ΔΕΝ ΣΥΝΑΙΝΩ ΚΟΥΤΙ Ο Χρήστης έχει 
              ενημερωθεί ότι μπορεί να ανακαλέσει ανά πάσα στιγμή στο μέλλον την χορηγηθείσα ανωτέρω συγκατάθεσή του. </p><p>Για να το πράξει αυτό ή προκειμένου να ασκήσει 
              τα δικαιώματα προστασίας προσωπικών δεδομένων του, γνωρίζει ότι μπορεί ανά πάσα στιγμή να απευθυνθεί για μεν την Roadcube στην ηλεκτρ. διεύθυνση 
              info@roadcube.com η/και στο τηλέφωνο 2103006633, για δε την Εταιρία στην ηλεκτρ. δ/νση : dpo@elin.gr</p><p> Η RoadCube και η Εταιρία δεσμεύονται ότι δεν θα 
              παράσχουν σε τρίτους τα προσωπικά στοιχεία του χρήστη χωρίς πρότερη δική του συναίνεση, εκτός και αν αυτό απαιτηθεί μέσω της νομίμου οδού (από το νόμο 
              ή βάση δικαστικής απόφασης) και ότι θα τα τηρούν με ασφάλεια για όσο χρονικό διάστημα λειτουργεί η εφαρμογή εφόσον πρόκειται για Χρήστες που δεν είναι 
              πελάτες της Εταιρίας και για όσο χρονικό διάστημα προβλέπεται στην σύμβαση μεταξύ Εταιρίας και … ως εκτελούσας την επεξεργασία. Η χρήση της Εφαρμογής 
              είναι ασφαλής για τον Χρήστη δεδομένου ότι η RoadCube χρησιμοποιεί τεχνολογία πληροφοριακών συστημάτων που είναι πιστοποιημένη με τα διεθνή πρότυπα ασφαλείας 
              PCI DSS μέσω της εταιρείας Tokenex. </p><p>Όροι ασφαλούς εκτέλεσης παραγγελίας :</p><p> (1) Την Εφαρμογή στην Ελλάδα προς το παρόν μπορούν να χρησιμοποιήσουν μόνον χρήστες 
              που είναι κάτοχοι ελληνικού κινητού τηλεφώνου, δηλαδή κινητού που ξεκινά με τα ψηφία 69... και καρτών που έχουν εκδοθεί από Ελληνικά πιστωτικά ιδρύματα.</p><p> (2) 
              Η Roadcube για κάθε εγγραφή οχήματος - για λόγους ασφαλείας - θα ταυτοποιεί τον Χρήστη, αποστέλλοντας με μήνυμα SMS έναν κωδικό ασφαλείας. Ο Χρήστης θα 
              καταχωρεί τον κωδικό αυτόν στην Εφαρμογή, προκειμένου στη συνέχεια να προχωρήσει στα επόμενα βήματα της εγγραφής του. </p><p>(3) Θα υπάρχει ημερήσιο όριο συναλλαγών 
              μόνο για τα πρατήρια ΕΛΙΝ ως εξής :</p><p> ▪ μέχρι του ποσού των 200 ευρώ, εφόσον πρόκειται για Ι.Χ. όχημα </p><p>▪ μέχρι του ποσού των 100 ευρώ, εφόσον πρόκειται για 
              μοτοσυκλέτα , </p><p>▪ μέχρι του ποσού των 400 ευρώ, εφόσον πρόκειται για VAN (ημιφορτηγό) </p><p>▪ Για φορτηγά και λεωφορεία παρακαλείστε όπως απευθυνθείτε στο τμήμα 
              κάρτας στόλου οχημάτων της ελίν στο τηλ. 210 6241500 ή 210 6241503 </p><p>(4) Ο χρήστης μπορεί να χρησιμοποιεί την Εφαρμογή προκειμένου να αγοράσει προϊόντα ή 
              υπηρεσίες από τα πρατήρια ελίν μεταξύ άλλων που εμφανίζονται στην Εφαρμογή. ΠΡΟΠΛΗΡΩΜΗ και ΠΛΗΡΩΜΗ καυσίμων, προϊόντων/υπηρεσιών ελίν: </p><p>Α) Πριν φτάσει ο 
              Χρήστης στο πρατήριο: Σε περίπτωση προαγοράς / προπληρωμής καυσίμων ή/και υπηρεσιών ο Χρήστης πρέπει πρώτα να επιλέξει από την Εφαρμογή το πρατήριο ελίν 
              από το οποίο επιθυμεί να εξυπηρετηθεί, το είδος του καυσίμου, την ποσότητα του καυσίμου σε € (π.χ. 30€ αμόλυβδη) ή όποιο άλλο προϊόν ή υπηρεσία διαθέτει 
              το πρατήριο. Στην συνέχεια προπληρώνει ηλεκτρονικά από το κινητό του με κάρτα (πιστωτική, χρεωστική ή προπληρωμένη) μέσω της Εφαρμογής την αξία του καυσίμου, 
              της υπηρεσίας ή άλλου προϊόντος.</p><p> Ως προς τα καύσιμα ο Χρήστης θα πρέπει να γνωρίζει ότι η τιμή του λίτρου στην εφαρμογή ταυτίζεται με την τιμή του λίτρου στον 
              πυλώνα του πρατηρίου και η επικαιροποίηση της τιμής στην Εφαρμογή γίνεται σε καθημερινή βάση από τον πρατηριούχο. Ο Χρήστης θα πρέπει επίσης να γνωρίζει ότι 
              σε περίπτωση που η ημέρα αγοράς καυσίμου από την Εφαρμογή διαφέρει από την ημέρα του ανεφοδιασμού του οχήματός του, η τιμή του λίτρου πιθανόν να έχει αλλάξει. 
              </p><p>Σε κάθε περίπτωση η χρέωση σε € αντιστοιχεί στην τιμή του λίτρου που ισχύει την ημέρα της αγοράς. Στην περίπτωση της προαγοράς / προπληρωμής καυσίμων ή/και 
              υπηρεσιών ο Χρήστης πρέπει να προσέλθει εντός δεκαπέντε (15) ημερών στο επιλεγέν από αυτόν πρατήριο προκειμένου να του παραδοθούν τα καύσιμα ή/και οι υπηρεσίες. 
              Την πέμπτη (5η ) ημέρα – εφόσον δεν προσέλθει – η Εφαρμογή θα αποστείλει στον Χρήστη υπενθύμιση (push notification) για την εξαργύρωση της προπληρωμής που έχει 
              πραγματοποιήσει. Εάν ο Χρήστης δεν ανταποκριθεί εντός των επομένων δέκα (10) ημερών από την αποστολή του 1ου push notification θα λάβει ένα 2ο push notification 
              που θα τον ενημερώνει ότι το ισόποσο της προπληρωμής έχει επιστραφεί (πιστωθεί) στην πιστωτική κάρτα του με την οποία αυτός έχει κάνει την προπληρωμή. Β) Επί 
              τόπου το πρατήριο: Εκτός από τη διαδικασία της προπληρωμής, ο Χρήστης έχει τη δυνατότητα όντας στο πρατήριο να πληρώνει, μέσω του κινητού του τηλεφώνου και 
              της Εφαρμογής, με πιστωτική, χρεωστική ή προπληρωμένη κάρτα τα προϊόντα και τις υπηρεσίες που παρέλαβε. Αυτό σημαίνει ότι θα πρέπει απαραίτητα ο Χρήστης για 
              λόγους ασφαλούς χρήσης του κινητού του τηλεφώνου, να εισέλθει στο γραφείο του πρατηρίου όπου θα ολοκληρώσει τη διαδικασία. H κάρτα του χρήστη (πιστωτική, 
              χρεωστική ή προπληρωμένη) θα χρεώνεται εκείνη τη στιγμή μέσω της Εφαρμογής. Κατά τα λοιπά ισχύει ό,τι περιγράφεται ανωτέρω στην παράγραφο Α. Στο πρόγραμμα 
              πιστότητας «ελίν up επιβράβευση» δεν υπάρχει δυνατότητα πληρωμής με κάρτες AMERICAN EXPRESS, DINERS και MAESTRO. ΠΡΟΓΡΑΜΜΑ ΕΠΙΒΡΑΒΕΥΣΗΣ ΕΛΙΝ UP ΕΠΙΒΡΑΒΕΥΣΗ 1) 
              </p><p>Για ONLINE πελάτες Παράλληλα με την αγορά καυσίμων/προϊόντων/υπηρεσιών από τα πρατήρια ελίν ο Χρήστης συμμετέχει και στο πρόγραμμα επιβράβευσης πιστού Χρήστη 
              «ελίν up επιβράβευση» μέσω της Εφαρμογής. </p><p>Το «ελίν Up επιβράβευση» είναι σχεδιασμένο να ανταμείβει τον Χρήστη που επιλέγει τα πρατήρια ελίν για τις αγορές του 
              μέσω της Εφαρμογής. Προϋπόθεση είναι ο Χρήστης να κατεβάσει στο κινητό του ΔΩΡΕΑΝ την Εφαρμογή RoadCube από το Google Play (Android) ή Apple Store (iPhone) 
              και να εγγραφεί σύμφωνα με την ανωτέρω διαδικασία. </p><p>Ο Xρήστης μέσω της Εφαρμογής, επιβραβεύεται με πόντους «ελίν upεπιβράβευση» με κάθε αγορά 
              καυσίμων/προϊόντων/υπηρεσιών από τα συμμετέχοντα στο πρόγραμμα πρατήρια ελίν . Ο Χρήστης αυτός αναφέρεται με τον όρο ΟΝ-LINE πελάτης. Ο Χρήστης 
              εξαργυρώνει τους πόντους σε δώρα του καταλόγου «ελίν up επιβράβευση». Εκτός από τον κατάλογο, όλα τα δώρα του προγράμματος είναι “ανεβασμένα” και στην 
              Eφαρμογή (RoadCube – ελίν Up επιβράβευση ).</p><p> Κάθε φορά που τα δώρα ανανεώνονται, θα αναρτώνται πρώτα στην Εφαρμογή με ευθύνη της Εταιρίας. Ειδικότερα, η 
              διαδικασία χρήσης της Εφαρμογής για το «ελίν up επιβράβευση» από τον Χρήστη είναι η εξής :</p><p> - Κατεβάζει δωρεάν την εφαρμογή RoadCube στο κινητό του τηλέφωνο. 
              Προϋπόθεση είναι να υπάρχει σύνδεση Internet. </p><p>- Κάνει την εγγραφή του. Για να μπορέσει ο χρήστης στην συνέχεια να εξαργυρώσει τους πόντους του και να παραλάβει 
              κάποιο δώρο θα πρέπει τα στοιχεία της αίτησής του να είναι σωστά και πλήρως συμπληρωμένα. - Εισάγει τον αριθμό κυκλοφορίας του οχήματος (πινακίδα) και την κάρτα του 
              (χρεωστική ή πιστωτική)</p><p> - Επιλέγει πρατήριο ελίν.</p><p> - Επιλέγει το προϊόν που επιθυμεί και πληρώνει (ή προπληρώνει) ηλεκτρονικά μέσα από την Εφαρμογή - Ο πρατηριούχος 
              βλέπει στον Η\Υ το αίτημα, επιβεβαιώνει αριθμό κυκλοφορίας & πληρωμή, ανεφοδιάζει με καύσιμο & δίνει την αντίστοιχη απόδειξη. </p><p>- Ο Χρήστης κερδίζει τους πόντους 
              «go4more» εφόσον εξοφλήσει με κάρτα go4more Εθνικής Τράπεζας ή «Επιστροφή σε €» εφόσον εξοφλήσει με κάρτα Eurobank. </p><p>Οι εξαργυρώσεις στα προγράμματα των τραπεζών 
              δεν μπορούν να πραγματοποιούνται από το κινητό τηλέφωνο παρά μόνο από το POS του πρατηρίου. - Από την πραγματοποιηθείσα αγορά, 
              ο χρήστης συλλέγει πόντους «ελίν up επιβράβευση» και κερδίζει δώρα. </p><p>Οι πόντοι συλλέγονται σύμφωνα με τα κατωτέρω :</p><p> - Πετρέλαιο κίνησης = 2 πόντοι /λίτρο - ii) 
              </p><p>Αμόλυβδη βενζίνη = 2 πόντοι / λίτρο - iii) CNG = 2 πόντοι / λίτρο - iv) </p><p>Σούπερ Αμόλυβδη Βενζίνη = 2 πόντοι / λίτρο - v) </p><p>Diesel Crystal = 4 πόντοι / λίτρο - vi) 
              </p><p>Unleaded Crystal = 4 πόντοι / λίτρο - vii) </p><p>Λοιπά προϊόντα και υπηρεσίες πρατηρίου = 2 πόντοι / ευρώ» - viii) </p><p>Υγραέριο = (Autogas)</p><p> Petrogaz 1 πόντος/λίτρο - - 
              </p><p>Είναι στη διακριτική ευχέρεια της Εταιρίας να τροποποιήσει τους πόντους που αντιστοιχούν σε κάθε προϊόν της ή διαδικασία επιβράβευσης μέσω Συνεργατών ή/και 
              Προμηθευτών της, 
              αφού πρώτα όμως ενημερώσει τους χρήστες μέσω της Εφαρμογής. </p><p>Ο Χρήστης θα ενημερώνεται από την Εταιρία μέσω της Εφαρμογής και όχι μέσω των Γενικών Όρων Χρήσης 
              και Προϋποθέσεων Roadcube. </p><p>O Χρήστης, 
              μόλις συλλέξει το σύνολο των πόντων που απαιτούνται για κάθε δώρο, 
              έχει τη δυνατότητα να εξαργυρώσει τους πόντους του με αντίστοιχο δώρο. </p><p>Ο Χρήστης μπορεί να ενημερώνεται για το σύνολο των πόντων του μέσω της Εφαρμογής. Μετά 
              από κάθε εξαργύρωση, 
              το υπόλοιπο αυτών θα διαμορφώνεται αυτόματα στην Εφαρμογή και ο Χρήστης θα μπορεί να ενημερώνεται σχετικά με το υπόλοιπό του.</p><p> ΣΥΛΛΟΓΗ ΠΟΝΤΩΝ ΜΕΣΩ ΣΥΝΕΡΓΑΤΩΝ 
              </p><p>Επιπλέον, 
              ο Χρήστης θα έχει τη δυνατότητα και ΣΥΛΛΟΓΗΣ πόντων και μέσω των Συνεργατών της ΕΛΙΝΟΙΛ. Δηλαδή, 
              Συνεργάτες της ΕΛΙΝΟΙΛ οι οποίοι συμμετέχουν στο πρόγραμμα «ελίν up επιβράβευση»s προσφέρουν πόντους ελίν στους πελάτες τους. Ειδικότερα: FIGAME.COM ΤΑΞΙΔΙΩΤΙΚΟΣ 
              ΟΡΓΑΝΙΣΜΟΣ ΜΕΠΕ </p><p>H εταιρία Figame.com θα επιβραβεύει τους πελάτες της προσφέροντας πόντους «ελίν up επιβράβευση» κατόπιν αγοράς αεροπορικού εισιτηρίου από το site 
              www.figame.gr για προορισμούς σε Ελλάδα και εξωτερικό.</p><p> Συγκεκριμένα:</p><p> ▪ O πελάτης αγοράζει αεροπορικό εισιτήριο από το www.figame.gr ▪ Στη συνέχεια λαμβάνει έναν 
              μοναδικό κωδικό που αντιστοιχεί στην κατηγορία του εισιτηρίου του. </p><p>▪ Κατεβάζει δωρεάν την Εφαρμογή RoadCube και εισάγει σε αυτήν τον μοναδικό κωδικό , 
              στο πεδίο που έχει οριστεί κερδίζοντας έτσι πόντους «ελίν up επιβράβευση». Οι πόντοι θα φορτώνονται αμέσως μόλις ο πελάτης του Συνεργάτη κατεβάσει την Εφαρμογή 
              και εισάγει τον κωδικό. </p><p>▪ Ο πελάτης βλέπει το πρόγραμμα επιβράβευσης «ελίν up επιβράβευση» και στην ιστοσελίδα www.figame.gr </p><p>▪ Οι πόντοι «ελίν up επιβράβευση» 
              που θα κερδίζει ο πελάτης του figame.gr είναι ανάλογα με το ποσό που έχει δαπανήσει για αγορές αεροπορικού εισιτηρίου από το figame.gr, 
              ως εξής:</p><p> Αξία αεροπορικού εισιτηρίου για αγορά από www.figame.gr Πόντοι ελίν που κερδίζει ο πελάτης που θα αγοράσει εισιτήριο από το www.figame.gr 0 έως 100 ευρώ 
              300 πόντοι 101 έως 200 ευρώ 600 πόντοι 201 έως 300 ευρώ 900 πόντοι 301 έως 400 ευρώ 1.200 πόντοι 401 έως 500 ευρώ 1.500 πόντοι 501 έως 600 ευρώ 1.800 πόντοι 601 
              έως 700 ευρώ 2.100 πόντοι 701 έως 800 ευρώ 2.400 πόντοι 801 έως 900 ευρώ 2.700 πόντοι 901 έως 1000 ευρώ 3.000 πόντοι 1.000 και άνω 3.500 πόντοι GROUPAMA ΦΟΙΝΙΧ 
              Α.Ε.Α.Ε.</p><p> Συλλογή Πόντων για Xρήστες – πελάτες της GROUPAMA Ασφαλιστικής </p><p>Στο πρόγραμμα μπορούν να συμμετέχουν όλοι οι πελάτες της GROUPAMA Ασφαλιστικής με εν ισχύ 
              ασφαλιστήριο συμβόλαιο ΙΧ αυτοκινήτου. </p><p>Με την έναρξη του προγράμματος, 
              κάθε πελάτης Groupama Ασφαλιστικής που θα ανανεώνει το ασφαλιστήριο συμβόλαιό του ΙΧ αυτοκινήτου θα επιβραβεύεται – εφόσον κατεβάσει την Εφαρμογή RoadCube - ως 
              εξής: * Για 3μηνη ανανέωση ασφαλιστηρίου, 
              </p><p>με 500 πόντους «ελίν up επιβράβευση» * Για 6μηνη ανανέωση ασφαλιστηρίου, 
             </p><p> με 1.000 πόντους «ελίν up επιβράβευση» * Για ετήσια ανανέωση ασφαλιστηρίου, 
             </p><p> με 2.000 πόντους «ελίν up επιβράβευση» Παράλληλα, 
              </p><p>• O κάθε πελάτης Groupama Ασφαλιστικής που θα κατεβάζει την πλατφόρμα RoadCube, 
              θα κερδίζει την πρώτη φορά 500 πόντους «ελίν up επιβράβευση» • O κάθε πελάτης Groupama Ασφαλιστικής που θα κατεβάσει την εφαρμογή Groupama Now, 
              θα κερδίζει την πρώτη φορά 500 πόντους «ελίν up επιβράβευση» Οι παραπάνω πόντοι αφορούν στην απόκτηση προϊόντων μέσω του καταλόγου των δώρων ‘up επιβράβευση ’ 
              της Εταιρίας. H Groupama Ασφαλιστική διατηρεί το δικαίωμα κατά την ανέλεγκτη κρίση της να τροποποιεί τους πόντους που απονέμει στους πελάτες της, 
              καθώς και το ποσοστό έκπτωσης που παρέχει στους Χρήστες της πλατφόρμας (και νέους πελάτες) μόνο ανοδικά. Ρητώς διευκρινίζεται ότι οι πόντοι που συλλέγει ο 
              Χρήστης από την Groupama Ασφαλιστική θα πρέπει να εξαργυρώνονται εντός πέντε ετών από την ημερομηνία της τελευταίας εξαργύρωσης πόντων. Σημείωση: H Eφαρμογή 
              θα ζητήσει από τον Χρήστη το ΑΦΜ και κωδικό πελάτη ή συμβολαίου της Groupama Ασφαλιστικής και εφόσον επιβεβαιωθεί ότι είναι πελάτης, 
              τότε η Εφαρμογή θα του αποδώσει αυτόματα τους αντίστοιχους πόντους. Μόνο ένας Χρήστης – πελάτης Groupama Ασφαλιστικής ανά ΑΦΜ θα μπορεί να συλλέξει πόντους 
              Groupama. 2) Για OFFLINE πελάτες Η Εταιρία δίνει τη δυνατότητα σε πελάτες της που δεν έχουν πρόσβαση στην Εφαρμογή (ΟFFLINE χρήστης) και κατά συνέπεια δεν 
              προβαίνουν σε αγορές προϊόντων μέσω κινητού τηλεφώνου- αλλά με μετρητά ή με κάρτα (πιστωτική/χρεωστική/προπληρωμένη) μέσω POS η με έντυπη κάρτα loyalty - να 
              είναι σε θέση και αυτοί να συμμετέχουν στο «ελίν up επιβράβευση» για τις αγορές τους από πρατήρια ελίν, 
              συλλέγοντας πόντους και στη συνέχεια να τους εξαργυρώνουν σε δώρα του καταλόγου/Εφαρμογής ως εξής : Α/ Ο πρατηριούχος καταχωρεί τα στοιχεία του OFFLINE πελάτη, 
              ο οποίος προμηθεύτηκε καύσιμα ή/και προϊόντα από το πρατήριο. </p><p>Ειδικότερα, 
              ο πελάτης συμπληρώνει στη φόρμα εγγραφής τα εξής: </p><p>• τον αριθμό κινητού του κινητού του τηλεφώνου </p><p>• τον αριθμό κυκλοφορίας (πινακίδα) του οχήματός του </p><p>• 
              τη μάρκα /μοντέλο του οχήματός του </p><p>• το e-mail του (προαιρετικά) </p><p>• το προϊόν που αγόρασε </p><p>• την αξία σε €υρώ του προϊόντος που αγόρασε. Τα στοιχεία αυτά συμπληρώνονται από τον πελάτη στο έντυπο Φόρμα Εγγραφής την 1η φορά, 
              που θα προμηθευθεί προϊόντα η/και υπηρεσίες από ένα πρατήριο. Ο offline Χρήστης συμφωνεί ρητά όπως η ΕΛΙΝΟΙΛ και η Roadcube συγκεντρώσουν τα ανωτέρω προσωπικά του στοιχεία, 
              για την εξυπηρέτηση του Χρήστη μέσω της Εφαρμογής στο πρόγραμμα «ελίν up επιβράβευση» .</p><p> Β/ Η αγορά προϊόντος/υπηρεσίας από τον OFFLINE πελάτη επιβραβεύεται με πόντους ως εξής: • ) Πετρέλαιο κίνησης = 1 πόντος /λίτρο • ii) Αμόλυβδη βενζίνη = 1 πόντος / λίτρο • iii) CNG = 1 πόντος / λίτρο • iv) Σούπερ Αμόλυβδη Βενζίνη = 1 πόντος / λίτρο • v) Diesel Crystal = 2 πόντοι / λίτρο • vi) Crystal Unleaded = 2 πόντοι / λίτρο • vii) Λοιπά προϊόντα και υπηρεσίες πρατηρίου = 1 πόντος / ευρώ • viii) Υγραέριο = (Autogas) Petrogaz 0,5 πόντος/λίτρο Ο ‘OFFLINE’ πελάτης δεν μπορεί να συλλέγει πόντους από τους προμηθευτές GROUPAMA & FIGAME.GR. Γ/ Μετά την εκάστοτε αγορά προϊόντων από τον OFFLINE πελάτη, 
              o τελευταίος θα λαμβάνει με SMS ή με e-mail ενημέρωση από την RoadCube για τους πόντους που έχει συλλέξει , 
              η οποία θα συνοδεύεται κάθε φορά (και μέχρι την τυχόν ένταξή του στην εφαρμογή) από το εξής κείμενο : « ΚΑΤΕΒΑΣΤΕ ΤΗΝ ΕΦΑΡΜΟΓΗ ROADCUBE ΑΠΟ ΤΟ ΚΙΝΗΤΟ ΣΑΣ ΤΗΛΕΦΩΝΟ ΚΑΙ ΚΕΡΔΙΣΤΕ ΔΙΠΛΑΣΙΟΥΣ ΠΟΝΤΟΥΣ ” στην πρώτη αγορά του μήνα. Δ/ ΔΙΑΔΙΚΑΣΙΑ ΕΞΑΡΓΥΡΩΣΗΣ ΠΟΝΤΩΝ (ΓΙΑ OFF-LINE ΠΕΛΑΤΕΣ) : Όταν ο OFFLINE πελάτης συλλέξει το σύνολο των πόντων που απαιτούνται μπορεί να τους εξαργυρώσει και να παραγγείλει το αντίστοιχο δώρο, 
              καλώντας στο Call Center (τηλ. 210 3006633) για να παραγγείλει το δώρο. Στο Call Center θα δηλώνει αριθμό κυκλοφορίας (πινακίδα) /αριθμό κινητού τηλεφώνου για την ταυτοποίηση των στοιχείων του και το δώρο που έχει επιλέξει από τον εκτυπωμένο Κατάλογο Δώρων «ελίν up επιβράβευση» o οποίος θα υπάρχει στο κάθε πρατήριο με επιμέλεια της Εταιρίας. Ταυτόχρονα θα πρέπει να δηλώσει το ονοματεπώνυμό του και τη διεύθυνση στην οποία θα παραλάβει το δώρο. O πελάτης OFF LINE, 
              μόλις συλλέξει το σύνολο των πόντων που απαιτούνται για κάθε δώρο, 
              θα έχει τη δυνατότητα είτε να εξαργυρώσει τους πόντους του με αντίστοιχο δώρο είτε να συνεχίσει να συλλέγει πόντους και να τους εξαργυρώσει σε δώρο για το οποίο απαιτούνται περισσότεροι πόντοι. ΔΙΕΥΚΡΙΝΙΣΕΙΣ ΓΙΑ ΤΗΝ EΞΑΡΓΥΡΩΣΗ ΠΟΝΤΩΝ ΑΠΟ ΤΟΝ ΧΡΗΣΤΗ Εξαργύρωση -παράδοση: Προκειμένου ο ONLINE Χρήστης να παραλάβει το δώρο που έχει επιλέξει να εξαργυρώσει, 
              θα πρέπει μέσω της Εφαρμογής να επιλέξει το προϊόν που επιθυμεί, 
              να συμπληρώσει στα πεδία που έχουν οριστεί μέσα στην Εφαρμογή τα σωστά στοιχεία του, 
              π.χ. το ονοματεπώνυμό του, 
              τη διεύθυνση αποστολής του δώρου, 
              το κινητό τηλέφωνο, 
              καθώς και το e-mail του. Ο Χρήστης θα παραλάβει το προϊόν που έχει επιλέξει με courier το συντομότερο. Την ευθύνη μετά την επιλογή του δώρου από την Εφαρμογή φέρει η Εταιρεία αποκλειστικά. Στην περίπτωση των δυσπρόσιτων ή νησιωτικών περιοχών η εταιρία courier θα συνεννοείται απ’ ευθείας με τον Χρήστη (παραλήπτη) σχετικά με την ημερομηνία παράδοσης. Για τον σκοπό αυτό ο χρήστης παρέχει την ρητή συγκατάθεσή του προς τις εταιρείες …???…………. να επικοινωνούν μαζί του στα στοιχεία επικοινωνίας που έχει δηλώσει κατά την εγγραφή του. O OFFLINE πελάτης, 
              προκειμένου να εξαργυρώσει το δώρο του, 
              θα πρέπει να καλέσει στο call Center στο τηλέφωνο 210 3006633. Σε περίπτωση που κάποιο από τα δώρα που είναι προς εξαργύρωση παύσει να διατίθεται ή δεν υπάρχει σε απόθεμα, 
              </p><p>ο Χρήστης θα πρέπει να εξαργυρώσει τους πόντους με άλλο είδος όμοιων ή παρεμφερών προδιαγραφών και ισόποσης αξίας, 
              το οποίο η Εταιρία θα έχει ανεβάσει στην Εφαρμογή. Η ευθύνη του τελευταίου όρου είναι της Εταιρίας και όχι της Roadcube. Κάθε είδος θα συνοδεύεται από Δελτίο Αποστολής, 
              το οποίο αποτελεί και την εγγύησή του. Tο Δελτίο Αποστολής θα εκδίδεται από τον Προμηθευτή που έχει αναλάβει την αντίστοιχη αποστολή δώρου. Η ευθύνη και η εγγύηση ανήκει στον Προμηθευτή. Η ισχύς της εγγύησης διαφέρει ανάλογα με το είδος σύμφωνα με ότι έχει προβλέψει ο Προμηθευτής με τον οποίο συμβάλλεται πλέον άμεσα ο Χρήστης. Σε περίπτωση που διαπιστωθεί από τον Χρήστη ελαττωματικότητα κάποιου προϊόντος ή εξαρτήματος αυτού, 
              ο προμηθευτής θα προβεί στη διόρθωση ή επισκευή του προβλήματος, 
              εφόσον, 
              η ελαττωματικότητα του προϊόντος δεν οφείλεται σε υπαιτιότητα του Χρήση.</p><p> Εφόσον κριθεί απαραίτητο μπορεί να γίνει αντικατάσταση του ελαττωματικού τμήματος ή/και
               ολόκληρου του προϊόντος. Εάν το ελαττωματικό προϊόν δεν υπάρχει πλέον λόγω εξαντλήσεως του αποθέματος του Προμηθευτή το είδος θα αντικατασταθεί με άλλο όμοιων 
               ιδιοτήτων και ισόποσης τιμής. Τα Αντικείμενα που παραδίδονται στους Χρήστες για λογαριασμό της Εταιρίας βρίσκονται σε καλή κατάσταση και είναι αρίστης ποιότητας, 
              κατάλληλα για τη συμφωνημένη χρήση και απαλλαγμένα από ελαττώματα. Είναι επίσης πλήρως προσαρμοσμένα στις απαριθμούμενες προδιαγραφές και συνομολογηθείσες 
              ιδιότητες και στα τεχνικά χαρακτηριστικά που ορίζουν η εθνική και κοινοτική Νομοθεσία. ΔΙΕΥΚΡΙΝΗΣΕΙΣ ΓΙΑ ΕΞΑΡΓΥΡΩΣΗ ΠΟΝΤΩΝ ΑΠΟ ΣΥΝΕΡΓΑΤΕΣ 1.NOVA (Forthnet 
              Ανώνυμη Εταιρεία Συνδρομητικής Τηλεόρασης, 
              Παροχής Τηλεπικοινωνιακών Υπηρεσιών και Συμμετοχών» και με δ.τ. « Forthnet Media S.A.» Η εταιρία NOVA συμμετέχει ως Προμηθευτής στο Πρόγραμμα επιβράβευσης. ». 
             </p><p> O Χρήστης του Προγράμματος προκειμένου να εξαργυρώσει πόντους» ελίν up επιβράβευση» μέσω της εφαρμογής έχει τη δυνατότητα να επιλέξει μεταξύ των δύο προϊόντων: • 
              Nova Cinema Pack και</p><p> • Nova Sports Pack Κάθε Προϊόν περιέχει: i) Δύο μήνες δωρεάν θέαση με προϋπόθεση την υποχρεωτική παραμονή του Χρήστη στο επιλεγμένο πακέτο 
              για περίοδο συνολικής διάρκειας δώδεκα (12) μηνών, 
              που σημαίνει ότι ο Χρήστης θα πρέπει να παραμείνει στην υπηρεσία NOVA για 12 μήνες και να καταβάλλει εξ ιδίων στη NOVA το αντίτιμο των υπόλοιπων δέκα (10) μηνών. 
              Η μηνιαία χρέωση της NOVA προς τον Χρήστη για το 10μηνο είναι 27,39€ (συμπ. ΦΠΑ & Τέλος Συνδρομητικής Τηλεόρασης). ii) Δωρεάν αποκωδικοποιητή Nova Box (σε όποια 
              περιοχή της Ελλάδας και αν βρίσκεται ο χρήστης. iii) Δωρεάν εγκατάσταση εξοπλισμού Nova (σε όποια περιοχή της Ελλάδας και αν βρίσκεται ο χρήστης). iv) Nova Go v) 
              Νοva HD. Κάθε φορά που ο Χρήστης θα εξαργυρώνει πόντους με κάποιο προϊόν NOVA, 
              θα επιλέγει το πακέτο NOVA που επιθυμεί (π.χ. Nova Cinema Pack ή Nova Sports Pack), θα εισάγει στοιχεία επικοινωνίας (ονομ/μο, κινητό, διεύθυνση, email) στην 
              εφαρμογή και στη συνέχεια η εταιρία NOVA θα επικοινωνεί μαζί του για να ξεκινήσει η διαδικασία εγκατάστασης και ενεργοποίησης της υπηρεσίας. Η ενεργοποίηση της 
              υπηρεσίας θα γίνει εντός δέκα (10) εργάσιμων ημερών από την ημέρα εξαργύρωσης των πόντων. Ο Χρήστης συμβάλλεται πλέον άμεσα με τον Προμηθευτή π.χ Nova. Τα 
              προϊόντα NOVA απευθύνονται αποκλειστικά και μόνο σε ενήλικες ιδιώτες, οι οποίοι είναι κάτοικοι Ελλάδος. Η παραχθείσα άδεια από τη ΝOVA καλύπτει αποκλειστικά 
              και μόνο την ιδιωτική/οικιακή προβολή, 
              αποκλειομένης κάθε άλλης. Απαγορεύεται ρητώς η χρήση παράνομων συσκευών μετάδοσης/αναμετάδοσης των προγραμμάτων ΝΟVA. Σε περίπτωση παράβασης της ανωτέρω πρόβλεψης, 
              η NOVA δύναται να ασκήσει ενώπιον των ποινικών ή/και αστικών δικαστηρίων (σύμφωνα με τις διατάξεις του Ν. 2121/1993, 
              του Π.Δ. 343/2002 και τις διατάξεις του Π.Κ.) κάθε ένδικο βοήθημα για την προστασία των δικαιωμάτων της. Τέλος, 
              σε περίπτωση που η εταιρία NOVA διαπιστώσει ότι κάποιος χρήστης προβαίνει σε πράξεις που σχετίζονται με τα Προϊόντα και αντίκεινται στις διατάξεις του 
              Ν. 2121/1993 ή/και του Π.Δ. 343/2002 ή/και του Π.Κ., 
              έχει το δικαίωμα –παράλληλα με τις τυχόν αξιώσεις που θα εγείρει κατά του παραβάτη- να διακόψει άμεσα τις προσφερόμενες υπηρεσίες οπτικοακουστικού περιεχομένου 
              προς τον εν λόγω καταναλωτή.</p><p>  2.RETAIL WORLD ΑΕ - ΑΝΩΝΥΜΗ ΕΜΠΟΡΙΚΗ ΕΤΑΙΡΙΑ ΠΡΟΙΟΝΤΩΝ ΚΑΙ ΥΠΗΡΕΣΙΩΝ ΤΕΧΝΟΛΟΓΙΑΣ, 
              ΕΠΙΚΟΙΝΩΝΙΑΣ, 
              ΨΥΧΑΓΩΓΙΑΣ ΚΑΙ ΠΟΛΙΤΙΣΜΟΥ (Public) Αφορά σε δωροεπιταγές αξίας 20€ & 50€: Η εταιρία PUBLIC συμμετέχει ως Προμηθευτής στο Πρόγραμμα επιβράβευσης , 
              μεταξύ άλλων, 
              με δωροεπιταγές αξίας 20€ & 50€. Για την εξαργύρωση των δωροεπιταγών Public, 
              ο δικαιούχος Χρήστης παραλαμβάνει τη δωροεπιταγή του από το κατάστημα Public που έχει επιλέξει μέσα από την Εφαρμογή, 
              αφού δηλώσει το ονοματεπώνυμό του επιδεικνύοντας ταυτόχρονα το δελτίο αστυνομικής του ταυτότητας ή παρεμφερές έγγραφο ταυτοποίησης των στοιχείων του 
              (διαβατήριο ή άδεια οδήγησης) και να δώσει τον αριθμό του κινητού του τηλεφώνου. Μετά την επιβεβαίωση των στοιχείων του και την παραλαβή της δωροεπιταγής , 
              θα υπογράψει την «Απόδειξη Παραλαβής της Δωροεπιταγής», 
              που θα συνοδεύεται από φωτοαντίγραφο της παραληφθείσας από τον καταναλωτή δωροεπιταγής. Η «Απόδειξη Παραλαβής της Δωροεπιταγής» θα ανεβαίνει στην 
              ηλεκτρονική πλατφόρμα από τον Προμηθευτή και θα επέχει θέση Δελτίου Αποστολής. Εφιστάται η προσοχή των Χρηστών στο ότι οι δωροεπιταγές έχουν διάρκεια 
              εννέα (9) μηνών από την ημερομηνία παραλαβής τους από τους δικαιούχους καταναλωτές στο υποδειχθέν κατάστημα, 
              κατά τα προαναφερόμενα. Ο Προμηθευτής και η Εταιρία δεν ευθύνονται σε περίπτωση απώλειας της δωροεπιταγής από τον δικαιούχο. </p><p> 3. GROUPAMA ΦΟΙΝΙΧ Α.Ε.Α.Ε. 
              Παροχές σε Xρήστες – νέους πελάτες Groupama Ασφαλιστικής Ο Χρήστης που συγκεντρώνει πόντους «ελίν up επιβράβευση» (και μόνο εφόσον είναι νέος πελάτης για 
              τη Groupama Ασφαλιστική) δύναται να τους εξαργυρώνει υπό τη μορφή εκπτώσεων σε ασφαλιστήριο συμβόλαιο ΙΧ αυτοκινήτου από την εταιρία Groupama Ασφαλιστική. 
             </p><p> Συγκεκριμένα: Νέος πελάτης θεωρείται ο χρήστης ο οποίος κατά τους τελευταίους δέκα οχτώ (18) μήνες πριν την παροχή της έκπτωσης δεν είχε κανένα ενεργό 
              συμβόλαιο στη Groupama Ασφαλιστική. Η επιβεβαίωση για την παροχή των ανωτέρω εκπτώσεων στους χρήστες θα γίνεται μέσω ελέγχου του AΦΜ του Xρήστη από την 
              εταιρία Groupama Ασφαλιστική. Ο Χρήστης θα έχει τη δυνατότητα να επιλέξει υπό τη μορφή δώρου μία από τις παρακάτω εκπτώσεις: * Έκπτωση 10% σε νέο ασφαλιστήριο 
              συμβόλαιο ΙΧ αυτοκινήτου, 
              όταν ο χρήστης συγκεντρώνει 600 πόντους «ελίν up επιβράβευση» (ο κωδικός που θα λάβει ο Χρήστης για να χρησιμοποιήσει την έκπτωση είναι 20903). * Έκπτωση 18% σε 
              νέο ασφαλιστήριο συμβόλαιο ΙΧ αυτοκινήτου , 
              όταν ο χρήστης συγκεντρώνει 1.500 πόντους «ελίν up επιβράβευση» (ο κωδικός που θα λάβει ο Χρήστης για να χρησιμοποιήσει την έκπτωση είναι 20905). Ο online Χρήστης 
              επιλέγει μέσα από τον κατάλογο της Εφαρμογής ποια έκπτωση επιθυμεί να εξαργυρώσει από τις ανωτέρω. Στη συνέχεια, 
              αφού συμπληρώσει τα στοιχεία του σε φόρμα ?? που θα εμφανιστεί στην Εφαρμογή (τηλέφωνο κινητό,email, 
              διεύθυνση, 
              ονοματεπώνυμο), 
              αρμόδιος υπάλληλος της Groupama Ασφαλιστικής θα επικοινωνήσει μαζί του για την ολοκλήρωση της διαδικασίας. Οι πελάτες με ενεργό συμβόλαιο στην Groupama Ασφαλιστική 
              δεν δικαιούνται να εξαργυρώνουν πόντους για την αγορά ασφαλιστηρίου συμβολαίου ΙΧ με έκπτωση. </p><p> 4. ΣΥΝΕΡΓΕΙΑ ΑΥΤΟΚΙΝΗΤΟΥ Αποκλειστικά για οχήματα Χρηστών της εφαρμογής, 
              η Εταιρία προσφέρει έκπτωση σε επιλεγμένο δίκτυο συνεργαζόμενων συνεργείων. Ειδικότερα όταν ο Χρήστης συγκεντρώσει 150 πόντους «ελιν up επιβράβευση» έχει τη 
              δυνατότητα να επιλέξει από την πλατφόρμα ‘Service Οχήματος” και να εξαργυρώσει αυτούς τους πόντους με έκπτωση 10% στον τιμοκατάλογο του Συνεργείου που θα επιλέξει ο 
              Χρήστης. Συγκεκριμένα: ο Χρήστης μέσω της Εφαρμογής βρίσκει την τοποθεσία του και τα κοντινότερα σε αυτόν συνεργεία. Στη συνέχεια, 
              η Εφαρμογή ζητά από τον Χρήστη να εισάγει τη μάρκα και το μοντέλο του οχήματός του. Ο Χρήστης ενημερώνεται μέσω της Εφαρμογής για τα συνεργεία που αναλαμβάνουν το 
              service για τη συγκεκριμένη μάρκα/μοντέλο, 
              τα ‘πακέτα’ του service, 
              την τιμή του service (με ΦΠΑ). Ο χρήστης επιλέγει συνεργείο, 
              κλείνει ραντεβού μέσω του τηλεφώνου του συνεργείου που «βλέπει» στην Εφαρμογή και ενημερώνεται τηλεφωνικά για το service. O Χρήστης θα εξυπηρετείται από το συνεργείο 
              βάσει του αριθμού κυκλοφορίας του οχήματός του και του μοντέλου του οχήματός του. Θα καταβάλλει δε το αντίτιμο του service μειωμένο κατά το ποσοστό έκπτωσης που 
              δικαιούται ΛΟΙΠΟΙ ΟΡΟΙ «ελίν up επιβράβευση» </p><p>▪ Ο Χρήστης δικαιούται να συναλλάσσεται με όλα τα πρατήρια ελίν που έχουν ενταχθεί στο πρόγραμμα πανελλαδικά, 
              για απόκτηση καυσίμων, 
              αγαθών και υπηρεσιών. Τα πρατήρια που συμμετέχουν στο πρόγραμμα φέρουν την ειδική σήμανση/λογότυπο «up επιβράβευση» και εμφανίζονται μέσα στην Εφαρμογή. Σε 
              περίπτωση 
              προαγοράς καυσίμου/αγαθού/υπηρεσίας από ένα συγκεκριμένο πρατήριο ελίν μέσω του κινητού τηλεφώνου, 
              ο Χρήστης μπορεί να προμηθευτεί τα παραπάνω μόνο σε αυτό το πρατήριο. Σε αντίθετη περίπτωση δεν αναγνωρίζεται η συναλλαγή. </p><p>▪ Για την προστασία του Χρήστη, 
              έχει τεθεί ημερήσιο όριο συναλλαγής ανά είδος οχήματος ως ανωτέρω. </p><p>▪ Ο Χρήστης οφείλει να γνωστοποιεί μέσω της Εφαρμογής τυχόν αλλαγή στοιχείων επικοινωνίας (π.χ. 
              διεύθυνσης / τηλ / email που δήλωσε), 
              διαφορετικά η ελίν δεν θα ευθύνεται σε περίπτωση απώλειας της αλληλογραφίας /δώρου και/ή διακοπής της επικοινωνίας. </p><p>▪ Η Roadcube δικαιούται να αποκλείει από την 
              Εφαρμογή οποιονδήποτε Χρήστη, 
              εφόσον αυτό επιβάλλεται από τον Νόμο ή Δικαστική Απόφαση. </p><p>▪ Η ΕΛΙΝΟΙΛ δικαιούται να ακυρώσει τη συναλλαγή του Προγράμματος εάν ο Χρήστης παραβεί οποιονδήποτε από 
              τους όρους της παρούσας, 
              οι οποίοι συμφωνούνται όλοι ουσιώδεις, 
              ή εάν συντρέχει άλλος σπουδαίος λόγος. </p><p>▪ Ο Χρήστης οφείλει να ελέγχει, 
              μετά από κάθε συναλλαγή, 
              την ακρίβεια και την ορθότητα των στοιχείων της απόδειξης που παραλαμβάνει από τον πρατηριούχο. Η Roadcube και ΕΛΙΝΟΙΛ ΑΕ δεν έχουν καμία υποχρέωση ή ευθύνη προς 
              τον Χρήστη για τυχόν αθέτηση εκπλήρωσης ή μη ακριβή εκπλήρωση των υποχρεώσεων των επιχειρήσεων ή πρατηρίων που συμμετέχουν στο Πρόγραμμα, 
              καθώς και για οποιαδήποτε συμβατική ή εξωσυμβατική αποζημίωσή του. </p><p>▪ Ο Χρήστης πρέπει να ενημερώσει αμέσως εγγράφως στο info@roadcube.com ή στο call centerΥπηρεσία 
              Εξυπηρέτησης Μελών «ελίν up επιβράβευση» 210 300 6633 σε περίπτωση που το κινητό του τηλέφωνο κλαπεί, 
              καταστραφεί ή χαθεί, 
              ή και σε περίπτωση που η χρήση του γίνει παράνομη ή αντικανονική, 
              ευθυνόμενος για κάθε είδους ζημία που ήθελε προκύψει μέχρι την εκ μέρους του ειδοποίηση. Τα δώρα που θα δικαιούται ο Χρήστης να επιλέξει βάσει των πόντων του, 
              θα γνωστοποιούνται σε αυτόν μέσω του καταλόγου, 
              τον οποίο θα παραλαμβάνει από το πρατήριο ελίν, 
              ή/και ηλεκτρονικά μέσω της Εφαρμογής. Η ΕΛΙΝΟΙΛ ΑΕ θα προβαίνει στην ανανέωση της λίστας δώρων σε τακτά χρονικά διαστήματα, 
              για τα οποία θα ενημερώνει εγκαίρως τους Χρήστες μέσω της Εφαρμογής αλλά και με έντυπα. Η ΕΛΙΝΟΙΛ διατηρεί το δικαίωμα να ακυρώσει τους πόντους ενός Χρήστη σε 
              περίπτωση που αυτός δεν έχει πραγματοποιήσει καμία συναλλαγή τους τελευταίους 12 μήνες (1 έτος).Η ΕΛΙΝΟΙΛ διατηρεί το δικαίωμα να διαγράψει τους πόντους ενός Χρήστη 
              σε περίπτωση που δεν προβεί σε καμία εξαργύρωση τους τελευταίους 60 μήνες (5 έτη). </p><p>▪ Σε περίπτωση που κάποιο από τα δώρα δεν υπάρχει, 
              λόγω εξάντλησης του αποθέματος ή απόσυρσής του από την παραγωγή, 
              τότε η Εταιρία δεσμεύεται να αντικαταστήσει το δώρο με άλλο ίδιων ή παρεμφερών προδιαγραφών και ισόποσου κόστους. </p><p>▪ Ο Χρήστης θα ενημερώνεται άμεσα μέσω της Εφαρμογής 
              για το σύνολο των πόντων που του αντιστοιχούν. Το σύνολο των πόντων που δικαιούται ο Χρήστης μετά από κάθε του συναλλαγή μέσω κινητού τηλεφώνου θα προστίθεται άμεσα 
              και θα είναι διαθέσιμο σε αυτόν άμεσα μέσα από την Εφαρμογή. Όταν ο Χρήστης εξαργυρώνει τους πόντους που αντιστοιχούν σε κάποιο δώρο, 
              τότε του αφαιρούνται οι αντίστοιχοι πόντοι. </p><p>▪ Σε περίπτωση που ο Χρήστης έχει προαγοράσει το καύσιμο αλλά για οποιονδήποτε λόγο δεν μπορεί να εφοδιαστεί, 
              τη στιγμή που επισκέπτεται το πρατήριο ελίν (πχ γιατί γίνεται ανεφοδιασμός του πρατηρίου από το βυτιοφόρο), 
              ο Χρήστης θα πρέπει να επανέλθει στο πρατήριο για τον ανεφοδιασμό. </p><p>▪ Για να θεωρηθεί έγκυρη η συναλλαγή, 
              θα πρέπει ο χρήστης να πληρώσει/ή προπληρώσει το καύσιμο ή/και την υπηρεσία. </p><p>▪ Ο Χρήστης θα μπορεί να εξαργυρώσει τους πόντους του εφ’ όσον έχει δώσει ρητή συγκατάθεση 
              για ηλεκτρονική καταχώριση των Προσωπικών του Δεδομένων, 
              όπως αυτά εμφανίζονται στην Εφαρμογή . </p><p>▪ Η Εταιρία διατηρεί τη διακριτική ευχέρεια να προσθέτει ή/και να αφαιρεί προϊόντα ή υπηρεσίες που συμμετέχουν στο πρόγραμμα 
              επιδότησης πόντων μέσω των πρατηρίων της ΕΛΙΝΟΙΛ A.E. ή άλλων συνεργαζόμενων επιχειρήσεων όποτε το κρίνει απαραίτητο. </p><p>▪ Σε περίπτωση που οι πόντοι έχουν αποκτηθεί με 
              τρόπο που δεν είναι σύμφωνος με την παραπάνω διαδικασία, 
              η ΕΛΙΝΟΙΛ A.E. θα διατηρεί το δικαίωμα να τους απορρίψει χωρίς καμία άλλη προειδοποίηση. </p><p>▪ Δεν υπάρχει δυνατότητα απόκτησης από τον Χρήστη ακριβότερου δώρου από 
              αυτό που αντιστοιχεί στους πόντους του με την καταβολή εκ μέρους του της χρηματικής διαφοράς . </p><p>▪ Τα δώρα αποκτώνται μόνο με τη συμπλήρωση του αριθμού των πόντων 
              που έχει προκαθορίσει η Εταιρία και έχει ανακοινώσει στον εκάστοτε εν ισχύ κατάλογο δώρων ή ηλεκτρονικά στην Εφαρμογή. Οι διαδικασίες παράδοσης δώρων ορίζονται 
              κάθε φορά από την Εταιρία και αναγράφονται στον ειδικό κατάλογο δώρων ή στην Εφαρμογή. Για την παραλαβή του δώρου ο Χρήστης θα πρέπει να επιδείξει οποιοδήποτε 
              απαραίτητο στοιχείο προσωπικής ταυτοποίησης του ζητηθεί μέσω της Εφαρμογής. </p><p>▪ Η εξαργύρωση των πόντων θα γίνεται μόνο με τους τρόπους που έχουν περιγραφεί 
              ανωτέρω. </p><p>▪ Σε περίπτωση που κάποιος δικαιούχος επισκεφθεί συνεργαζόμενη επιχείρηση για να παραλάβει το δώρο του αλλά έχει ξεχάσει τον κωδικό εξαργύρωσης, 
              τότε δεν θα μπορεί να παραλάβει το δώρο του. </p><p>▪ Τα δώρα που είναι σε καλή κατάσταση και σύμφωνα με την περιγραφή τους στον ειδικό κατάλογο δώρων ή στην πλατφόρμα 
              και εφ’ όσον έχουν παραγγελθεί από τον δικαιούχο, 
              σε καμία περίπτωση δεν θα μπορούν εκ των υστέρων να επιστραφούν ή να μη γίνουν αποδεκτά. </p><p>▪ Τα δώρα που αντιστοιχούν σε συγκεκριμένα επίπεδα πόντων δεν μπορούν να 
              ανταλλαγούν με άλλα διαφορετικής αντιστοιχίας σε πόντους. Ο κάτοχος δεν μπορεί να «αγοράσει» πόντους με τη χρήση μετρητών ή οιουδήποτε άλλου μέσου συναλλαγών. 
              Τα δώρα που αντιστοιχούν σε συγκεκριμένους πόντους δεν μπορούν επίσης να ανταλλαγούν με άλλον τρόπο που δεν έχει μέχρι εκείνη τη στιγμή ανακοινωθεί από την 
              Εταιρία. </p><p>▪ Τα δώρα από έναν κατάλογο δεν μπορούν να ανταλλαγγούν με δώρα προηγούμενου καταλόγου, 
              εκτός εάν η Εταιρία A.E. έχει προβλέψει και έχει ανακοινώσει κάτι σχετικό. Σε περίπτωση, 
              τέλος, 
              που κάποιο δώρο είναι ελαττωματικό ισχύουν όσα έχουν περιγραφεί ανωτέρω στην παράγραφο «Γενικές διευκρινίσεις για την εξαργύρωση πόντων από τον Χρήστη» .</p><p> ▪ Ο Χρήστης 
              μπορεί να επικοινωνεί τηλεφωνικά με το τηλέφωνο εξυπηρέτησης (call center) 210 3006633 για θέματα που αφορούν στο πρόγραμμα «ελίν up επιβράβευση», 
              αλλά και εγγράφως στο info@roadcube.com. Μη γενική Παραίτηση - Ακυρότητα Μέρους: Στο μέτρο που οποιοσδήποτε όρος κριθεί παράνομος, 
              άκυρος ή ανεφάρμοστος εν όλω ή εν μέρει, δυνάμει οποιουδήποτε νόμου η δικαστικής απόφασης , τότε ο όρος αυτός δεν θα ισχύει, χωρίς ωστόσο να θίγεται η ισχύς των 
              υπολοίπων παρόντων όρων και προϋποθέσεων. Επίλυση διαφορών, εφαρμοστέο δίκαιο και δικαιοδοσία Για οποιαδήποτε διαφορά απορρέουσα από την λειτουργία της Εφαρμογής 
              και την εκτέλεση του προγράμματος πιστότητας θα καταβάλλεται προσπάθεια εξωδικαστικής επίλυσής της, άλλως αυτή θα επιλύεται με προσφυγή στα αρμόδια δικαστήρια που 
              με τους παρόντες ορίζονται τα δικαστήρια της Αθήνας. e-mail επικοινωνίας: info@roadcube.com ???? Τηλέφωνο επικοινωνίας: 210 300 66 33</p>
      </v-container>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {        
        
      };
    },
  };
</script>
